import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import PageTitle from "@components/admin/PageTitle";
import SearchComponent from "@components/SearchComponent";
import TabbedTableComponent from "@components/TabbedTableComponent";
import { Header } from "@components/TableComponent";
import Button from "@components/web/Button";
import { queryApi } from "@hooks/api";
import { Box, Card, Collapse, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, TableCell, TableRow, TextField } from "@material-ui/core";
import { Block, CheckCircleOutline, HelpOutline, RadioButtonUncheckedOutlined } from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AdminAdminBrandsAllCountriesGetResponse, AdminAdminBrandsAllItemCategoriesGetResponse, AdminGetAllBrandsBrandResponse } from "@reshopper/admin-client";
import { performAction } from "@utils/actions";
import { get as levenshtein } from "fast-levenshtein";
import { sortBy, take } from "lodash";
import { useEffect, useMemo, useState } from "react";

export default withAdminMenuLayout(() => {
	const [searchTerm, setSearchTerm] = useState("");

	const [allCategories] = queryApi(
		async () =>
			await adminClient().adminBrandsAllItemCategoriesGet(),
		[]
	);

	const [allCountries] = queryApi(
		async () =>
			await adminClient().adminBrandsAllCountriesGet(),
		[]
	);

	const tableHeaders: Array<Header<AdminGetAllBrandsBrandResponse>> = [
		{ databaseSortingKey: null, label: "" },
		{ databaseSortingKey: x => x.amountOfTimesSeen, label: "Times seen", isDefault: true },
		{ databaseSortingKey: x => x.amountOfTimesSuggested, label: "Times suggested since last review"}
	];

	return <div>
		<PageTitle title="Brands" />
		<Card style={{
			marginTop: 30,
			marginBottom: 10
		}}>
			<SearchComponent
				onSearchTermChanged={setSearchTerm}
				onSearch={async () => { }} />
		</Card>

		<Card>
			{allCategories && allCountries && <TabbedTableComponent<AdminGetAllBrandsBrandResponse> tabs={[
				{
					label: "All reviewed brands",
					table: {
						call: async (options) => await adminClient().adminBrandsGet({ tabOptions: "allReviewed", ...options, searchTerm: searchTerm }),
						dependencies: [searchTerm],
						isSelectable: false,
						sortDirection: "desc",
						size: 25,
						additionalQueryParameters: {
							isDisabled: true
						},
						headers: tableHeaders,
						renderRow: (brand) => <BrandRow
							key={`brand-${brand.name}`}
							brand={brand}
							allCategories={allCategories}
							allCountries={allCountries} />
					},
					width: "270px"
				},
				{
					label: "Reviewed brands with new suggestions",
					table: {
						call: async (options) => await adminClient().adminBrandsGet({ tabOptions: "reviewedPending", ...options, searchTerm: searchTerm }),
						dependencies: [searchTerm],
						isSelectable: false,
						sortDirection: "desc",
						size: 25,
						additionalQueryParameters: {
							isDisabled: true
						},
						headers: tableHeaders,
						renderRow: (brand) => <BrandRow
							key={`brand-${brand.name}`}
							brand={brand}
							allCategories={allCategories}
							allCountries={allCountries} />
					},
					width: "420px"
				},
				{
					label: "New brands pending review",
					table: {
						call: async (options) => await adminClient().adminBrandsGet({ tabOptions: "new", ...options, searchTerm: searchTerm }),
						dependencies: [searchTerm],
						isSelectable: false,
						sortDirection: "desc",
						size: 25,
						additionalQueryParameters: {
							isDisabled: true
						},
						headers: tableHeaders,
						renderRow: (brand) => <BrandRow
							key={`brand-${brand.name}`}
							brand={brand}
							allCategories={allCategories}
							allCountries={allCountries} />
					},
					width: "360px"
				}
			]}
			/>}
		</Card>
	</div>;
});

function BrandRow(props: {
	brand: AdminGetAllBrandsBrandResponse,
	allBrands?: AdminGetAllBrandsBrandResponse[],
	allCategories: AdminAdminBrandsAllItemCategoriesGetResponse,
	allCountries: AdminAdminBrandsAllCountriesGetResponse
}) {
	const [isOpen, setIsOpen] = useState(false);

	const [nameTextFieldValue, setNameTextFieldValue] = useState(props.brand.name);

	const [aliases, setAliases] = useState(props.brand.lowercaseAliases || []);
	const [aliasTextFieldValue, setAliasTextFieldValue] = useState("");

	const brandAliasSuggestions = useMemo(
		() => take(
			sortBy(
				props.allBrands?.filter(x => !aliases.find(a => a.toLowerCase() == x.name.toLowerCase()))
					.map(b => ({
						levenshteinDistance: levenshtein(
							b.name,
							props.brand.name,
							{
								useCollator: true
							}),
						brand: b
					}))
					.filter(x => x.levenshteinDistance < props.brand.name.length / 2),
				x => x.levenshteinDistance),
			10).map(x => x.brand),
		[
			props.allBrands,
			aliases
		]);

	const [validatedCategories, setValidatedCategories] = useState(props.brand.validatedCategories);
	const [unvalidatedCategories, setUnvalidatedCategories] = useState(props.brand.unvalidatedCategories);
	const [suggestedCategories, setSuggestedCategories] = useState(props.brand.suggestedCategories);

	const [validatedCountries, setValidatedCountries] = useState(props.brand.validatedCountries);
	const [unvalidatedCountries, setUnvalidatedCountries] = useState(props.brand.unvalidatedCountries);
	const [suggestedCountries, setSuggestedCountries] = useState(props.brand.suggestedCountries);

	const onChange = (args: {
		values: {
			unvalidated: string[],
			validated: string[],
			suggested: string[]
		},
		setters: {
			validated: (value: string[]) => void,
			unvalidated: (value: string[]) => void,
			suggested: (value: string[]) => void
		}
	}) => {
		args.setters.validated(args.values.validated);
		args.setters.unvalidated(args.values.unvalidated);
		args.setters.suggested(args.values.suggested);
	};

	const onSave = async () => {
		if (!confirm("Are you sure? If you added aliases, any items using these aliases will also have their brands changed."))
			return;

		await performAction(async () =>
			await adminClient().adminBrandApprovePost({
				body: {
					existingName: props.brand.name,
					newName: nameTextFieldValue,
					validatedCategories: validatedCategories,
					validatedCountries: validatedCountries,
					aliases: aliases,
					unvalidatedCountries: unvalidatedCountries,
					unvalidatedCategories: unvalidatedCategories
				}
			}),
			undefined,
			true);
	}

	const unvalidateAllCategoriesAndCountries = async ( {
	}) => {
		setUnvalidatedCategories(props.allCategories.itemCategories)
		setUnvalidatedCountries(props.allCountries.countries)
		setValidatedCategories([])
		setValidatedCountries([])
		setSuggestedCategories([])
		setSuggestedCountries([])
	}

	const onCollapseChanged = () => {
		const newState = !isOpen;
		setIsOpen(newState);
	}

	return <>
		<TableRow>
			<TableCell>
				<Button
					size="small"
					onClick={onCollapseChanged}
				>
					{isOpen ?
						<KeyboardArrowUpIcon /> :
						<KeyboardArrowDownIcon />}
				</Button>
			</TableCell>
			<TableCell
				component="th"
				scope="row"
				width="100%"
				style={suggestedCategories.length > 0 || suggestedCountries.length > 0 ? {
					color: 'black',
					fontWeight: 'bold'
				} : {
					color: 'black'
				}}>
				{props.brand.name}
			</TableCell>
		</TableRow>


		<TableRow>
			<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
				<Collapse in={isOpen} timeout="auto" unmountOnExit
					style={{
						width: '100%',
						marginBottom: 30
					}}
				>
					<h2>Name</h2>
					<p><i>This is the correct representation of the brand. In other words, how the brand should look like.</i></p>
					<TextField
						label="Target name"
						onChange={e => setNameTextFieldValue(e.target.value)}
						value={nameTextFieldValue}
						style={{
							marginBottom: 50
						}} />

					<h2>Aliases</h2>
					<p><i>When a new item is created with an alias as its brand, the item brand is instead changed to the name of the brand. Case sensitivity does not matter here.</i></p>
					<Box style={{
						marginBottom: 50
					}}>
						<List dense style={{
							width: 400,
							border: "1px solid rgba(0,0,0,0.1)"
						}}>
							{aliases.map((alias, index) => <ListItem>
								<ListItemText primary={alias} />
								{index > 0 && <ListItemSecondaryAction>
									<IconButton
										edge="end"
										onClick={() => setAliases(aliases.filter(a => a !== alias))}
									>
										<DeleteIcon />
									</IconButton>
								</ListItemSecondaryAction>}
							</ListItem>)}
							{brandAliasSuggestions
								.map(suggestion => <ListItem>
									<ListItemText primary={<>
										<b>Suggestion:</b> <span style={{
											color: "green"
										}}>
											{suggestion.name}
										</span>
									</>} />
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											onClick={() => {
												setAliases([...aliases, suggestion.name]);
											}}
										>
											<Add />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>)}
						</List>
						<Box style={{
							display: "flex"
						}}>
							<TextField
								label="Alias name"
								onChange={e => setAliasTextFieldValue(e.target.value)}
								value={aliasTextFieldValue} />
							<Button
								variant="outlined"
								color="primary"
								onClick={() => {
									setAliases([...aliases, aliasTextFieldValue]);
									setAliasTextFieldValue("");
								}}
								style={{
									alignSelf: "flex-end",
									marginLeft: 10
								}}
							>
								Add
							</Button>
						</Box>
					</Box>
					<h2>Categories and countries</h2>
					<p>
						<span style={{
							fontWeight: suggestedCategories.length > 0 ? 'bold' : 'normal'
						}}>
							{suggestedCategories.length} categor{suggestedCategories.length === 1 ? "y" : "ies"} pending review
						</span> and <span style={{
							fontWeight: suggestedCountries.length > 0 ? 'bold' : 'normal'
						}}>
							{suggestedCountries.length} countr{suggestedCountries.length === 1 ? "y" : "ies"} pending review
						</span>
					</p>
					<p><i>These are all of the categories and countries available for the brand.</i></p>
					<List
						dense={true}
						style={{
							marginTop: -10
						}}>
						<IconListItem
							icon={<CheckCircleOutline />}
							text={"Validated (reviewed)"} />
						<IconListItem
							icon={<Block />}
							text={"Unvalidated (reviewed)"} />
						<IconListItem
							icon={<HelpOutline />}
							text={"Suggested (pending review)"} />
						<IconListItem
							icon={<RadioButtonUncheckedOutlined />}
							text={"Neiher suggested nor reviewed"} />
					</List>
					<Box style={{
						display: 'flex',
						flexDirection: 'row',
						gap: 100
					}}>
						<ValidationList
							validatedValues={validatedCategories}
							unvalidatedValues={unvalidatedCategories}
							suggestedValues={suggestedCategories}
							allValues={props.allCategories.itemCategories}
							onChange={values => onChange({
								values,
								setters: {
									validated: setValidatedCategories,
									unvalidated: setUnvalidatedCategories,
									suggested: setSuggestedCategories
								}
							})} />
						<ValidationList
							validatedValues={validatedCountries}
							unvalidatedValues={unvalidatedCountries}
							suggestedValues={suggestedCountries}
							allValues={props.allCountries.countries}
							onChange={values => onChange({
								values,
								setters: {
									validated: setValidatedCountries,
									unvalidated: setUnvalidatedCountries,
									suggested: setSuggestedCountries
								}
							})} />
					</Box>
					<Button style={{ marginRight: 10}} variant="contained" color="secondary" onClick={unvalidateAllCategoriesAndCountries}>
						Set all to unvalidated
					</Button>
					<Button variant="contained" color="primary" onClick={onSave} disabled={suggestedCategories.length > 0 || suggestedCountries.length > 0 ? true : false}>
						Save
					</Button>
				</Collapse>
			</TableCell>
		</TableRow>
		<TableCell>
			{props.brand.amountOfTimesSeen}
		</TableCell>
		<TableCell>
			{props.brand.amountOfTimesSuggested}
		</TableCell>
	</>
}

function ValidationList(props: {
	validatedValues: string[],
	unvalidatedValues: string[],
	suggestedValues: string[],
	allValues: string[],
	onChange: (values: {
		unvalidated: string[],
		validated: string[],
		suggested: string[]
	}) => void
}) {
	return <List dense>
		{props.allValues.map((value) => {
			const isValidated = props.validatedValues.indexOf(value) > -1;
			const isUnvalidated = props.unvalidatedValues.indexOf(value) > -1;
			const isSuggested = props.suggestedValues.indexOf(value) > -1;

			const [stateReferencingValueAndIcon, setValueAndIconReferencingState] = useState<boolean | null | undefined>(undefined);
			const [icon, setIcon] = useState(<RadioButtonUncheckedOutlined />);

			useEffect(() => {
				if (isValidated) {
					setValueAndIconReferencingState(true);
					setIcon(<CheckCircleOutline />)
				}
				else if (isUnvalidated) {
					setValueAndIconReferencingState(false);
					setIcon(<Block />);
				}
				else if (isSuggested) {
					setValueAndIconReferencingState(null);
					setIcon(<HelpOutline />)
				}
			}, [props.unvalidatedValues, props.validatedValues, props.suggestedValues])

			const onClick = () => {
				switch (stateReferencingValueAndIcon) {
					case true:
						props.onChange({
							unvalidated: [
								...props.unvalidatedValues,
								value
							],
							validated: props.validatedValues.filter(v => v !== value),
							suggested: props.suggestedValues.filter(v => v !== value)
						});
						setValueAndIconReferencingState(false);
						setIcon(<Block />);
						break;
					case false:
					case null:
					case undefined:
						props.onChange({
							unvalidated: props.unvalidatedValues.filter(v => v !== value),
							suggested: props.suggestedValues.filter(v => v !== value),
							validated: [
								...props.validatedValues,
								value
							]
						});
						setValueAndIconReferencingState(true);
						setIcon(<CheckCircleOutline />)
						break;
				};
			};

			return <ListItem
				dense
				button
				onClick={onClick}
			>
				<ListItemIcon>
					<ChangableIconButton
						state={stateReferencingValueAndIcon}
						icon={icon}
						tabIndex={-1}
						onClick={onClick}
					/>
				</ListItemIcon>
				<ListItemText
					primary={value.toLocaleUpperCase()}
					primaryTypographyProps={{
						style: {
							fontWeight: stateReferencingValueAndIcon === null ? "bold" : "normal"
						}
					}}
				/>
			</ListItem>
		})}
	</List>
}

function ChangableIconButton(props: {
	state: boolean | null | undefined,
	icon: JSX.Element,
	tabIndex: number,
	onClick: () => void
}) {

	return <>
		<IconButton
			tabIndex={props.tabIndex}
			onClick={props.onClick}>
			{props.icon}
		</IconButton>
	</>
};

function IconListItem(props: {
	icon: JSX.Element,
	text: string
}) {

	return <>
		<ListItem
			style={{
				marginLeft: -10
			}}>
			<ListItemIcon>
				<svg
					width={20}
					height={20}>
					{props.icon}
				</svg>
			</ListItemIcon>
			<ListItemText
				primary={props.text}
				primaryTypographyProps={{
					style: {
						fontStyle: "italic"
					}
				}}
				style={{
					margin: -20
				}}
			/>
		</ListItem>
	</>
}